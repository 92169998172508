import { render, staticRenderFns } from "./PerSalary.vue?vue&type=template&id=66b692d8&scoped=true"
import script from "./PerSalary.vue?vue&type=script&lang=js"
export * from "./PerSalary.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b692d8",
  null
  
)

export default component.exports