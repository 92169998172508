<template>
    <div>
        综合信息统计
    </div>
</template>

<script>
    export default {
        name: "StaAll"
    }
</script>

<style scoped>

</style>